export const Notice_Data = [
    {
        id:"1",
        name:"Lorem ipsum dolor sit amet consectetur",
        date:"January 29 1948",
        about:"This about Something 1",
        source:"https://drive.google.com/file/d/15JFJGgOk5sSiUNmhTIbvIY9RX_icObHS/view?usp=sharing"
    },
    {
        id:"2",
        name:"Lorem ipsum dolor sit amet consectetur",
        date:"January 29 1948",
        about:"This about Something 2",
        source:"../Image Components/Magazin.pdf"
    },
    {
        id:"3",
        name:"Lorem ipsum dolor sit amet consectetur",
        date:"January 29 1948",
        about:"This about Something 3",
        source:"../Image Components/Magazin.pdf"
    },
    {
        id:"4",
        name:"Lorem ipsum dolor sit amet consectetur",
        date:"January 29 1948",
        about:"This about Something 4",
        source:"../Image Components/Magazin.pdf"
    },
    {
        id:"5",
        name:"Lorem ipsum dolor sit amet consectetur",
        date:"January 29 1948",
        about:"This about Something 5",
        source:"https://drive.google.com/file/d/15JFJGgOk5sSiUNmhTIbvIY9RX_icObHS/view?usp=sharing"
    },
    {
        id:"6",
        name:"Academic Calender",
        date:"November 10 2023",
        about:"This about Something 5",
        source:"https://drive.google.com/file/d/1fEGt1A1m8twDTyhGx8whcGMiyDUJSPBZ/view?usp=drive_link"
    },
    {
        id:"7",
        name:"Notice on training program on on Custom Analog Design Flow usin",
        date:"April 04 2024",
        about:"This about Online Training",
        source:"https://drive.google.com/file/d/1bfkHpwyfdW_MzNU-hlApeeA0k7kcNIqd/view?usp=drive_link"
    },
    {
        id:"8",
        name:"Notice on training program on RedHawk-SC EDA Tool",
        date:"April 04 2024",
        about:"This about Online Training",
        source:"https://drive.google.com/file/d/1ENIgNh_SfXD9fIRC82PtZ7Qm-XVwPaSA/view?usp=drive_link"
    },
    {
        id:"9",
        name:"Notice on training program on Siemens Tessent EDA Tool",
        date:"April 24 2024",
        about:"This about Online Training",
        source:"https://drive.google.com/file/d/1ezaVzcCh6OPGb6R1_0T3-BJM7FSTiSSY/view?usp=drive_link"
    },
    {
        id:"10",
        name:"AICTE APPROVAL PROCESS 2024-25",
        date:"MAY 10 2024",
        about:"This about AICTE APPROVAL PROCESS 2024-25",
        source:"https://drive.google.com/file/d/19N3Ntmug-NavGNfj-DI8AjfJyCcYGsuB/view?usp=sharing"
    },
    {
        id:"11",
        name:"Admission Notice 2024-25",
        date:"June 20 2024",
        about:"This about Admission Notice 2024-25",
        source:"https://drive.google.com/file/d/12VN_ydSpl0Ffs9MZbrFAUkBZ_hgntHh5/view?usp=sharing"
    },
    {
        id:"12",
        name:"Brochure 2024-25",
        date:"June 20 2024",
        about:"This about Brochure 2024-25",
        source:"https://drive.google.com/file/d/1JPz6kWtwaWfuzwzKu6TGQmpXHfbOb2Wx/view?usp=sharing"
    }
    
]